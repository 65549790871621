
const attr = {
  cover: 'data-counter',
  input: 'data-counter-input',
  decrement: 'data-counter-decrement',
  increment: 'data-counter-increment',
  min: 'data-counter-min',
  max: 'data-counter-max',
  step: 'data-counter-step',
}


const init = () => {
  // const covers = [...document.querySelectorAll(`[${attr.cover}]`)]
  // covers.forEach(addCounter)

  document.addEventListener('click', clickHandler)

}


function clickHandler(event) {
  if (event.target.closest(`[${attr.decrement}]`) || event.target.closest(`[${attr.increment}]`)) {
    const cover = event.target.closest(`[${attr.cover}]`)
    const input = cover.querySelector(`[${attr.input}]`)
    const min = input.getAttribute('min') ?? -Infinity
    const max = input.getAttribute('max') ?? Infinity
    const step = input.getAttribute('step') ?? 1

    input.value = input.value || 0

    if(event.target.closest(`[${attr.decrement}]`)) setValue(+input.value - +step)
    if(event.target.closest(`[${attr.increment}]`)) setValue(+input.value + +step)

    function setValue(newValue) {
      input.value = newValue

      if (+input.value < +min) input.value = min
      if (+input.value > +max) input.value = max

    }

  }
}

// function addCounter(cover) {
//   const input = cover.querySelector(`[${attr.input}]`)
//   const min = input.getAttribute('min') ?? -Infinity
//   const max = input.getAttribute('max') ?? Infinity
//   const step = +cover.getAttribute(attr.step) || 1
//   const decrementBtn = cover.querySelector(`[${attr.decrement}]`)
//   const incrementBtn = cover.querySelector(`[${attr.increment}]`)
//   input.value = input.value || 0

//   decrementBtn.addEventListener('click', () => setValue(+input.value - step))
//   incrementBtn.addEventListener('click', () => setValue(+input.value + step))



//   function setValue(newValue) {
//     input.value = newValue
//     console.log(min, newValue);

//     if (+input.value < +min) input.value = min
//     if (+input.value > +max) input.value = max

//   }

// }


export default { init }