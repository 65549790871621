import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger.js";
import throttle from "./throttle";


const gsapAnimation = {
  fadeInLeft: {
    from: { x: '-50%', opacity: 0 },
    to: { x: 0, opacity: 1 }
  },
  fadeInRight: {
    from: { x: '50%', opacity: 0 },
    to: { x: 0, opacity: 1 }
  },
  fadeInUp: {
    from: { y: '50%', opacity: 0 },
    to: { y: 0, opacity: 1 }
  },
  fadeInDown: {
    from: { y: '-50%', opacity: 0 },
    to: { y: 0, opacity: 1 }
  },
  fadeIn: {
    from: { opacity: 0 },
    to: { opacity: 1 }
  },
  scaleIn: {
    from: { scale: 1.5 },
    to: { scale: 1 }
  },
}

const sheetsMouseListeners = []

function gsapInit() {
  const scrollElements = document.querySelectorAll('.gsap-scroll');
  scrollElements.forEach((element) => {
    const key = element.getAttribute('data-animation')
    const duration = element.getAttribute('data-duration') || 1.00
    const end = element.getAttribute('data-end') || 'top center'
    const start = element.getAttribute('data-start') || 'top 95%'
    const delay = element.getAttribute('data-delay') || 0.100

    const instance = gsap.fromTo(element, gsapAnimation[key].from, {
      ...gsapAnimation[key].to,
      duration,
      delay,
      scrollTrigger: {
        trigger: element.parentElement,
        start: start,
        end: end,
        // scrub: true,
        // markers: true,
        // id: "scrub"
      }
    });

  })


  const sheets = document.querySelectorAll('.info__img-sheet')
  sheets.forEach((sheet, index) => {
    const sheetWrap = sheet
    const sheetItem = sheet.querySelector('div')
    const sheetInner = sheet.querySelector('span')
    const speed = sheet.getAttribute('data-speed')

    let toLeft = 0;
    let dir = 'left'
    if (index % 2 == 0) {
      toLeft = (0 + Math.random() * 10) + '%'
    } else {
      toLeft = (85 + Math.random() * 10) + '%'
      dir = 'right'
    }

    gsap.to(sheetWrap, {
      left: toLeft,
      opacity: 1,
      scrollTrigger: {
        trigger: '#info',
        start: "top center",
        end: 'bottom top',
        // scrub: true,
        // markers: true,
        // id: "scrub"
      }
    });
    gsap.to(sheetItem, {
      y: 800 * speed,
      scrollTrigger: {
        trigger: '.sheets-wrapper',
        start: "top bottom",
        end: 'bottom top',
        scrub: 1,
        // markers: true,
        // id: "scrub"
      }
    });


    const fromVars = {
      transform: `translate(${dir == 'left' ? '' : '-'}${30 + Math.random() * 30}px, 10px)`
    }
    const toVars = {
      transform: `translate(${dir == 'left' ? '-' : ''}${30 + Math.random() * 30}px, 10px)`,
      ease: "power1.in"
    }

    const instance = gsap.fromTo(sheetInner, fromVars, toVars);
    instance.pause()
    sheetsMouseListeners.push(instance)
  })

  const sheets1 = document.querySelectorAll('.sale__sheet')
  sheets1.forEach((sheet) => {
    const sheetWrap = sheet
    const sheetItem = sheet.querySelector('div')
    const sheetInner = sheet.querySelector('span')
    const speed = sheet.getAttribute('data-speed')
    gsap.to(sheetItem, {
      y: 200 * speed,
      scrollTrigger: {
        trigger: '#sale',
        start: "top bottom",
        end: 'bottom top',
        scrub: 1,
        // markers: true,
        // id: "scrub"
      }
    });

    const instance = gsap.fromTo(sheetInner, {
      transform: `translate(-${30 + Math.random() * 30}px, 10px)`
    }, {
      transform: `translate(${30 + Math.random() * 30}px, 0)`,
      ease: "power1.in"
    });
    instance.pause()
    sheetsMouseListeners.push(instance)
  })

  gsap.to('.info__img img', {
    y: -100,
    scrollTrigger: {
      trigger: '#info',
      start: "top bottom",
      end: 'bottom top',
      scrub: true,
      // markers: true,
      // id: "scrub"
    }
  });



  const contacts = document.querySelectorAll('.contact__item')
  contacts.forEach((sheet) => {
    const instance = gsap.to(sheet, {
      boxShadow: '12px 12px 0 #faf0ce',
      scrollTrigger: {
        trigger: '#contact',
        start: "top center",
        end: 'bottom center',
        scrub: true,
        // markers: true,
        // id: "scrub"
      }
    });
  })

  const gelenFlowers = document.querySelectorAll('.gelen__title img')
  gelenFlowers.forEach((flower, index) => {
    gsap.to(flower, {
      scale: 1,
      scrollTrigger: {
        trigger: '.gelen',
        start: "top 75%",
        end: 'bottom center',
        scrub: true,
        // markers: true,
        // id: "scrub"
      }
    });

    const coof = index % 2 == 0 ? 1 : -1
    const instance = gsap.fromTo(flower, {
      rotateZ: `${coof * 10}deg`,
      x: coof * 10
    }, {
      rotateZ: `${-coof * 10}deg`,
      x: -coof * 10,
      ease: "power1.in"
    });
    instance.pause()
    sheetsMouseListeners.push(instance)
  })

  gsap.fromTo('.gelen__title', {
    background: `
      radial-gradient(163px 163px at 25% center, rgba(115, 81, 174, 0) 5%, rgba(0, 0, 0, 0)) 10%, 
      radial-gradient(163px 163px at 65% 80%, rgba(53, 140, 39, 0) 5%, rgba(0, 0, 0, 0)) 10%, 
      var(--color-text)`,
  }, {
    background: `
      radial-gradient(163px 163px at 25% center, rgba(115, 81, 174, 1) 25%, rgba(0, 0, 0, 0)) 100%, 
      radial-gradient(163px 163px at 65% 80%, rgba(53, 140, 39, 1) 25%, rgba(0, 0, 0, 0)) 100%, 
      var(--color-text)`,
    scrollTrigger: {
      trigger: '.gelen',
      start: "top 75%",
      end: 'bottom center',
      scrub: true,
      // markers: true,
      // id: "scrub"
    }
  });

  const infoSheet1 = gsap.fromTo('.info__content-sheet--1', {
    transform: 'translate(-20px, 10px) scale(0.9) rotateZ(-30deg)'
  }, {
    transform: 'translate(0, 0) scale(1) rotateZ(0deg)',
    ease: "power1.in"
  });
  infoSheet1.pause()
  const infoSheet2 = gsap.fromTo('.info__content-sheet--2', {
    transform: 'translate(-20px, 10px) scale(0.9) rotateZ(-30deg)'
  }, {
    transform: 'translate(0, 0) scale(1) rotateZ(0deg)',
    ease: "power1.in"
  });
  infoSheet2.pause()
  const infoSheet3 = gsap.fromTo('.info__content-sheet--3', {
    rotateZ: '180deg'
  }, {
    rotateZ: '0deg',
    ease: "power1.in"
  });
  infoSheet3.pause()
  sheetsMouseListeners.push(infoSheet1)
  sheetsMouseListeners.push(infoSheet2)
  sheetsMouseListeners.push(infoSheet3)
  window.addEventListener('mousemove', throttle(mouseMoveHandler, 1000 / 60))



  // map-btn icon change


}


function mouseMoveHandler(event) {
  const x = event.clientX
  const width = document.body.clientWidth
  const percent = Math.min((2 * Math.abs(width / 2 - x)) / width, 1)

  sheetsMouseListeners.map((item) => {
    item.progress(percent)
  })
}



const init = () => {
  gsap.registerPlugin(ScrollTrigger);
  gsapInit()


}

export default { init }