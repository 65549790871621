// import inputmask from './js/inputmask'
import datepicker from './js/datepicker'
import AirDatepicker from 'air-datepicker';
import localeRu from 'air-datepicker/locale/ru';

import counter from './js/counter'
import fancybox from "./js/fancybox";
import myGsap from "./js/gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger.js";
import tippy, { animateFill, createSingleton } from 'tippy.js';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/dist/backdrop.css';
import 'tippy.js/animations/shift-away.css';

import UI from './ui/js/index'
import './ui/ui-reset.scss'
import './ui/ui-core.scss'
import './ui/ui-example.scss'



import './scss/frontend--fonts.scss'
import './scss/frontend--style.scss'
import { isTouchDevice } from './js/isTouchDevice';
import phonemask from './js/phonemask/phonemask';

window.airDatePickerOptions = {
  locale: localeRu
}
window.AirDatepicker = AirDatepicker;
window.tippy = tippy
window.animateFill = animateFill
window.createSingleton = createSingleton
window.isTouchDevice = isTouchDevice


UI.init()


document.addEventListener('DOMContentLoaded', loadHandler)
window.addEventListener('scroll', scrollHandler)
document.addEventListener('click', clickHandler);


// open menu handler
document.addEventListener('toggleopen', (event) => {
  if (event.detail.target.id == 'menu') {
    document.body.classList.add('menu-opened')
  }
})

document.addEventListener('toggleclose', (event) => {
  if (event.detail.target.id == 'menu') {
    document.body.classList.remove('menu-opened')
  }
})

document.addEventListener('tabchange', (event) => {
  if (event.detail.target.closest('#catalog')) {
    console.log('tabchange');
    ScrollTrigger.refresh()
  }
})

function loadHandler(event) {
  datepicker.init(document)
  // inputmask.init(document)
  phonemask.init('[type="tel"]')
  counter.init()
  fancybox.init()
  document.body.classList.add('loaded');
  myGsap.init()

  setTimeout(() => {
  }, 1000)
}








function scrollHandler(event) {
  const nav = document.querySelector('.-nav-')
  if(!nav){
    return
  }
  if (nav.getBoundingClientRect().top <= 0) {
    nav.classList.add('fixed')
  } else {
    nav.classList.remove('fixed')

  }
}

function clickHandler(event) {
  function scrollTo() {
    const target = event.target.closest('[data-scroll]');
    const href = target.getAttribute('data-scroll');

    if (!href) return;
    if (href[0] != '#' || href == '#') return;

    event.preventDefault();

    var element = document.querySelector(href);
    const offset = 45;
    const bodyRect = document.body.getBoundingClientRect().top;
    const elementRect = element.getBoundingClientRect().top;
    const elementPosition = elementRect - bodyRect;
    const offsetPosition = elementPosition - offset;

    window.scrollTo({
      top: offsetPosition,
      behavior: 'smooth'
    });
  }
  if (event.target.closest('[data-scroll]')) scrollTo();



}
